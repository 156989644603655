<template>
    <div class="sky" :class="[modalStatus, loading]" :data-night="night">
        <div class="islands">

            <div v-if="night" class="uk">
                <ImageLoader float="true" src="https://images.blighty-assets.com/uk-island-night.png" alt="United Kingdom Island" />
                <div class="cloud-1">
                    <ImageLoader float="true" src="https://images.blighty-assets.com/cloud-1-night.png" alt="Cloud" />
                </div>
                <div class="cloud-2">
                    <ImageLoader float="true" src="https://images.blighty-assets.com/cloud-2-night.png" alt="Cloud" />
                </div>
            </div>

            <div v-else class="uk">
                <ImageLoader float="true" src="https://images.blighty-assets.com/uk-island.png" alt="United Kingdom Island" />
                <div class="cloud-1">
                    <ImageLoader float="true" src="https://images.blighty-assets.com/cloud-1.png" alt="Cloud" />
                </div>
                <div class="cloud-2">
                    <ImageLoader float="true" src="https://images.blighty-assets.com/cloud-2.png" alt="Cloud" />
                </div>
            </div>

            <div v-if="night" class="canada">
                <ImageLoader float="true" src="https://images.blighty-assets.com/canada-island-night.png" alt="Canada Island" />
                <div class="cloud-1">
                    <ImageLoader float="true" src="https://images.blighty-assets.com/cloud-1-night.png" alt="Cloud" />
                </div>
                <div class="cloud-2">
                    <ImageLoader float="true" src="https://images.blighty-assets.com/cloud-3-night.png" alt="Cloud" />
                </div>
            </div>

            <div v-else class="canada">
                <ImageLoader float="true" src="https://images.blighty-assets.com/canada-island.png" alt="Canada Island" />
                <div class="cloud-1">
                    <ImageLoader float="true" src="https://images.blighty-assets.com/cloud-1.png" alt="Cloud" />
                </div>
                <div class="cloud-2">
                    <ImageLoader float="true" src="https://images.blighty-assets.com/cloud-3.png" alt="Cloud" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import ImageLoader from '@/components/ImageLoader.vue'

    export default {
        name: "Islands",

        components: {
            ImageLoader
        },

        data: () => ({
            modalStatus: "",
            loading: 'loading',
            night: false
        }),

        created () {
            this.updateModal()
            this.updateLoading()
        },

        computed: {
            getModalStatus () {
                return this.$store.getters.modalStatus
            },
            getNight () {
                return this.$store.getters.night
            }
        },

        watch: {
            getModalStatus: function () {
                this.updateModal()
            },
            getNight: function (status) {
                this.night = status
            }
        },

        methods: {
            updateModal () {
                if(this.$store.getters.modalStatus == 'modal-open') {
                    this.modalStatus = this.$store.getters.modalStatus
                } else {
                    this.modalStatus = 'modal-closed'
                }
            },
            updateLoading () {
                setTimeout( () => this.loading = '', 500)
            }
        }
    }
</script>

<style lang="scss">
    .sky {
        @include container();
        transition: all 1.5s ease;
        padding: 0;

        &.loading {
            opacity: 0;
            max-width: 100%;
            transform: scale(1.1);
            transition: all .6s ease;
        }

        &.modal-open {
            transition: all 1s ease;

            @media screen and (min-width: 1000px) {
                max-width: 90%;
            }
        }

        &.modal-closed {
            transition: all 1s ease;
        }

        .islands {
            display: flex;
            flex-direction: row;
            margin: 0 auto;
            position: relative;

            @media screen and (min-width: 1000px) {
                padding-top: 250px;
            }

            @media screen and (min-width: 1200px) {
                padding-top: 160px;
            }

            .uk {
                animation: islandFloat 6s ease-in-out infinite;
                animation-delay: 0.8s;
                position: absolute;
                top: 180px;
                left: 0;
                width: 50%;

                @media screen and (min-width: 650px) {
                    top: 220px;
                }

                @media screen and (min-width: 1000px) {
                    width: 40%;
                    position: relative;
                    top: auto;
                    left: auto;
                    align-content: flex-start;
                    padding: 200px 0 0 0;
                }

                .cloud-1 {
                    animation: float 6s ease-in-out infinite;
                    max-width: 100px;
                    position: absolute;
                    top: 40px;
                    right: -100px;
                    opacity: 0.4;

                    @media screen and (min-width: 1000px) {
                        top: 350px;
                        right: 0;
                    }

                    @media screen and (min-width: 1200px) {
                        top: 380px;
                    }
                }

                .cloud-2 {
                    animation: float2 6s ease-in-out infinite;
                    animation-direction: alternate;
                    max-width: 70px;
                    position: absolute;
                    top: 0;
                    right: -200px;
                    opacity: 1;

                    @media screen and (min-width: 1000px) {
                        top: 350px;
                        left: 0;
                        right: auto;
                        opacity: 0.7;
                    }

                    @media screen and (min-width: 1200px) {
                        max-width: 100px;
                        top: 400px;
                    }
                }
            }

            .canada {
                animation: islandFloat 6s ease-in-out infinite;
                position: absolute;
                top: 260px;
                left: 0;

                @media screen and (min-width: 650px) {
                    top: 300px;
                }

                @media screen and (min-width: 1000px) {
                    position: relative;
                    top: auto;
                    left: auto;
                }

                .cloud-1 {
                    animation: float 6s ease-in-out infinite;
                    max-width: 250px;
                    position: absolute;
                    top: 350px;
                    left: 0;
                    opacity: 0.6;
                    transform: translateY(0px);

                    @media screen and (min-width: 768px) {
                        top: 400px;
                        max-width: 200px;
                    }

                    @media screen and (min-width: 1000px) {
                        max-width: 250px;
                    }

                    @media screen and (min-width: 1200px) {
                        max-width: 300px;
                        top: 600px;
                    }
                }

                .cloud-2 {
                    animation: float 6s ease-in-out infinite;
                    animation-delay: 0.8s;
                    max-width: 120px;
                    position: absolute;
                    top: 270px;
                    right: 0;
                    opacity: 0.6;

                    @media screen and (min-width: 768px) {
                        top: 350px;
                    }

                    @media screen and (min-width: 1000px) {
                        max-width: 180px;
                        position: absolute;
                        top: 350px;
                        right: 0;
                        opacity: 0.6;
                    }

                    @media screen and (min-width: 1200px) {
                        max-width: 200px;
                        top: 400px;
                    }
                }
            }
        }
    }
    @include float( -20px );
    @include float2( -10px );
    @include islandFloat( -15px );
</style>