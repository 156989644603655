import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    page: "page-closed",
    api: String,
    requestedId: String,
    drawStatus: "draw-open",
    modalStatus: String,
    navStatus: String,
    night: false,
    sun: String,
    moon: String,
    siteTitle: 'Blighty Design | Dan Dunsire | Full Stack Web Developer'
  },
  getters: {
    siteTitle (state) {
      return state.siteTitle
    },
    pageStatus (state) {
      return state.page
    },
    drawStatus (state) {
      return state.drawStatus
    },
    modalStatus (state) {
      return state.modalStatus
    },
    navStatus (state) {
      return state.navStatus
    },
    night (state) {
      return state.night
    },
    sun (state) {
      return state.sun
    },
    moon (state) {
      return state.moon
    },
    apiContent (state) {
      return state.api
    },
    getRequested (state) {
      // Filter the current object in the array
      return Object.values(state.api).filter(content => content.id === state.requestedId);
    }
  },
  mutations: {
    updatePage (state, status) {
      state.page = status
    },
    updateDraw (state, status) {
      state.drawStatus = status
    },
    updateModal (state, status) {
      state.modalStatus = status
    },
    updateNav (state, status) {
      state.navStatus = status
    },
    updateNight (state, status) {
      state.night = status
    },
    updateSun (state, status) {
      state.sun = status
    },
    updateMoon (state, status) {
      state.moon = status
    },
    updateApi (state, content) {
      state.api = content
    },
    setRequested (state, id) {
      state.requestedId = id
    }
  },
  actions: {
    getContent (mutations, endpoint) {
      axios.get(endpoint)
      .then(function (response) {
          mutations.commit('updateApi', response.data.data)
      }).catch(
        function (error) {
          console.log('Error: ' + error)
        })
    }
  },
  modules: {},
});
