import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/assets/sass/app.scss'
import axios from "axios";

axios.defaults.baseURL = 'https://api.blighty.design/api/'

createApp(App).use(store).use(router).mount("#app");
