<template>
    <img :class="{ 'show': onLoaded == true }" @load="imageLoaded" :src="src" :alt="alt">
    <div :class="{ 'float': float == 'true'}" v-if="loadingMsg">
        <Loader />
    </div>
</template>

<script>
import Loader from '@/components/Loader.vue'

    export default {
        name: "ImageLoader",

        components: {
            Loader
        },

        props: {
            src: String,
            alt: String,
            float: String
        },

        data: () => ({
            onLoaded: false,
            loadingMsg: true
        }),

        methods: {
            imageLoaded () {
                this.onLoaded = true
                this.loadingMsg = false
            }
        }
    }
</script>

<style lang="scss" scoped>
img {
    display: block;
    opacity: 0;

    &.show {
        opacity: 1;
        transition: all 2s ease;
    }
}

.float {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>