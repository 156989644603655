<template>
  <div class="wrapper" :data-night="night">

    <div :class="moon" class="moon">
      <img src="https://images.blighty-assets.com/moon.png" alt="moon">
    </div>
    <div :class="sun" class="sun">
      <img src="https://images.blighty-assets.com/sun.png" alt="Sun">
    </div>

    <div class="menu">
      <div class="mob">
        <div class="nav-btn" @click="toggleNav()" :class="nav">
          <div class="bars">
            <span class="bar-1"></span>
            <span class="bar-2"></span>
            <span class="bar-3"></span>
          </div>
        </div>
      </div>
      
      <nav :class="nav">
        <div @click="go('/')" :class="isActive('Home')">Home</div>
        <span>&mdash;</span>
        <div @click="go('/about-me')" :class="isActive('About')">About</div>
        <span>&mdash;</span>
        <div @click="go('/projects/websites')" :class="isActive('Websites')">Websites</div>
        <span>&mdash;</span>
        <div @click="go('/projects/designs')" :class="isActive('Designs')">Designs</div>
      </nav>
    </div>
    <router-view />

    <Islands />

    <div v-if="night" class="lights" :data-night="night">Social Butterfly? <span @click="lightsToggle();">Try this.</span></div>
    <div v-else class="lights" :data-night="night">Night Owl? <span @click="lightsToggle();">You may prefer this.</span></div>
  </div>
</template>
<script>
import Islands from '@/components/Islands.vue'

export default {
  name: "App",
  components: {
    Islands
  },
  data: () => ({
    night: false,
    sun: 'not-set',
    moon: 'not-set',
    nav: 'closed',
    onLoaded: false,
    loadingMsg: true
  }),

  created () {
    setTimeout( () => this.sun = 'set', 500)
    setTimeout( () => this.moon = 'not-set', 1000)
    // Default state is overflow: hidden to hide the intro animation scrollbars, then reset the overflow once complete
    setTimeout( () => document.body.style.overflow = "auto", 1000)
  },

  computed: {
    getNight () {
      return this.$store.getters.night
    },
    getDraw () {
      return this.$store.getters.drawStatus
    },
    getNav () {
      return this.$store.getters.navStatus
    }
  },

  watch: {
    getNight: function(data) {
        this.night = data
    },
    getDraw: function(status) {
      if(status == 'draw-open') {
          this.$store.commit('updateNav', 'closed')
      }
    },
    getNav: function(status) {
        this.nav = status
    }
  },

  methods: {
    go (path) {
      this.$store.commit('updatePage', 'page-closed')
      setTimeout( () => this.$router.push(path), 400)
      this.closeNav()
    },
    isActive (name) {
      if (this.$route.name === name) {
          return 'active'
      } else {
          return 'inactive'
      }
    },
    lightsToggle () {
      if(this.night == false) {
        this.$store.commit('updateNight', true)
        this.sun = 'going-down'
        this.moon = 'set'
        document.body.classList.remove('day')
        document.body.classList.add('night')
      } else {
        this.$store.commit('updateNight', false)
        this.moon = 'going-down'
        this.sun = 'set'
        document.body.classList.remove('night')
        document.body.classList.add('day')
      }
    },
    loaded () {
      this.onLoaded = true
      this.loadingMsg = false
    },
    openNav () {
      this.$store.commit('updateNav', 'open')
    },
    closeNav () {
      this.$store.commit('updateNav', 'closed')
    },
    toggleNav () {
      if(this.nav == 'closed') {
        this.openNav()
      } else {
        this.closeNav()
      }
    }
  }
}
</script>

<style lang="scss">
  body {
    font-family: 'VT323', monospace;
    font-size: 1.4rem;
    color: $dark-sky;
    background: url("https://images.blighty-assets.com/clouds-background.png") center -100px repeat $sky;
    transition: all 1s ease;
    overflow: hidden;
  }

  [v-cloak] {
    display: none;
  }

  .day {
    background: url("https://images.blighty-assets.com/clouds-background.png") center top repeat $sky;
    transition: all 1s ease;

    @media screen and (min-width: 500px) {
      background: url("https://images.blighty-assets.com/clouds-background.png") center top repeat $sky;
    }
  }

  .night {
      background: url("https://images.blighty-assets.com/clouds-background-night.png") center top repeat $night-sky;
      transition: all 1s ease;
  }

  .wrapper {
    overflow: hidden;

    .sun, .moon {
      position: absolute;
      transition: all 1s ease;
      opacity: 0;

      &.not-set,
      &.going-down {
        top: 500px;
        right: 500px;
        transition: all 1s ease;
        opacity: 0;
      }

      &.set {
        max-width: 200px;
        top: 160px;
        right: 0;
        transition: all 1s ease;
        opacity: 1;

        @media screen and (min-width: 500px) {
          max-width: 250px;
          top: 200px;
          right: 0;
        }

        @media screen and (min-width: 600px) {
          max-width: 300px;
          top: -100px;
          right: 0;
        }

        @media screen and (min-width: 1000px) {
          max-width: 450px;
          top: -150px;
          right: 100px;
        }

        @media screen and (min-width: 1200px) {
          max-width: none;
          top: -250px;
          right: 100px;
        }
      }
    }

    .lights {
      position: fixed;
      bottom: 15px;
      left: 15px;
      z-index: 4;
      font-size: 1.2rem;

      @media screen and (min-width: 500px) {
        font-size: 1.4rem;
      }

      span {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }

      &[data-night="true"] {
        color: $sky;
      }
    }
  }

  a {
    color: $white;
  }

  .menu {

    .mob {
      position: relative;

      .nav-btn {
        position: absolute;
        top: 15px;
        left: 15px;
        color: $white;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        font-size: 1.6rem;
        line-height: 0;
        cursor: pointer;
        text-shadow: 1px 1px 8px $dark-sky;
        background: rgba(93,118,127,0.7);
        color: rgb(210, 224, 230);
        border-radius: 50%;

        @media screen and (min-width: 576px) {
          display: none;
        }

        .bars {
          position: relative;
          height: 100%;
          width: 100%;

          .bar-1,
          .bar-2,
          .bar-3 {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 55%;
            height: 4px;
            background: $white;
            transition: all .6s ease;
          }

          .bar-1 { top: 12px; }
          .bar-2 { top: 22px; }
          .bar-3 { top: 32px; }
        }

        &.open {
          left: 175px;
          transition: all .6s ease;
          background: $dirt-dark;

          .bars {
            .bar-1,
            .bar-2,
            .bar-3 {
              transition: all .6s ease;
            }

            .bar-1 {
              top: 23px;
              transform: rotate(45deg);
            }
            .bar-2 {
              opacity: 0;
            }
            .bar-3 {
              top: 23px;
              transform: rotate(-45deg);
            }
          }
        }

        &.closed {
          left: 15px;
          transition: all .6s ease;
        }
      }
    }

    nav {
      @include container();
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      text-transform: uppercase;
      color: $off-white;
      background: rgba(33, 36, 43, 0.97);
      padding: 20px 0;
      z-index: 5;
      width: 160px;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      right: auto;
      font-size: 2rem;
      transition: all .6s ease;

      &.open {
        left: 0;
        z-index: 5;
        transform: rotate(0);
      }

      &.closed {
        left: -350px;
        transform: rotate(-20deg);

        @media screen and (min-width: 576px) {
          left: 0;
          transform: rotate(0);
        }
      }

      @media screen and (min-width: 576px) {
        width: 100%;
        max-width: none;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0;
        top: 20px;
        background: transparent;
        font-size: 1.4rem;
      }

      // Dashes
      span {
        display: none;

        @media screen and (min-width: 576px) {
          display: block;
        }
      }

      // Links
      div {
        position: relative;
        margin: 0 10px;
        cursor: pointer;

        &:first-of-type {
          @media screen and (min-width: 576px) {
            padding-left: 0;
            margin-left: 0;
          }
        }

        // Active smiley
        &:before {
            position: absolute;
            top: 100px;
            left: -30px;
            text-align: center;
            content: '\25A0';
            font-size: 1.4rem;

            @media screen and (min-width: 576px) {
              top: -55px;
              left: 0;
              right: 0;
            }
        }

        @media screen and (min-width: 576px) {
          @include underlineLink();
        }


        &.active {
            position: relative;
            margin-left: 30px;
            animation: activeColor .4s ease-in-out;
            animation-fill-mode: forwards;
            animation-delay: .2s;
            cursor: default;
            transition: all .6s ease;

            @media screen and (min-width: 576px) {
              margin-right: 30px;
            }

            &:hover:after {
                background: transparent;
            }

          &:before {
            animation: activeMoveMob .3s ease-in-out;
            animation-fill-mode: forwards;

            @media screen and (min-width: 576px) {
              animation: activeMove 1s ease-in-out;
              animation-fill-mode: forwards;
            }
          }
        }

        &.inactive {
            animation: inactiveColor .4s ease-in-out;
            animation-fill-mode: forwards;
            transition: all .6s ease;

          &:before {
            animation: inactiveMoveMob .2s ease-in-out;
            animation-fill-mode: forwards;

            @media screen and (min-width: 576px) {
              animation: inactiveMove .4s ease-in-out;
              animation-fill-mode: forwards;
            }
          }
        }
      }
    }
  }

  [data-night="true"] {
    .menu {
      .mob {
        .nav-btn {
          background: rgba(67, 74, 89, 0.97);
          border: 1px solid #212024;

          &.open {
            background: $dirt-dark;
          }
        }
      }
    }
  }

  $navActive: $dirt-dark;

  @keyframes activeColor {
    80% {
      color: $navActive;
    }
    90% {
      color: $navActive;
    }
    100% {
      color: $navActive;
    }
  }
  @keyframes inactiveColor {
    0% {
      color: $navActive;
    }
  }
  @keyframes activeMoveMob {
    0% {
      top: 50%;
      transform: translateY(-50%);
      left: -25px;
    }
    100% {
      top: 50%;
      transform: translateY(-50%);
      left: -20px;
      color: $navActive;
    }
  }
  @keyframes activeMove {
    0% {
      top: -50px;
    }
    60% {
      top: -14px;
    }
    80% {
      top: -18px;
    }
    100% {
      top: -18px;
      color: $navActive;
    }
  }
  @keyframes inactiveMoveMob {
    0% {
      top: 50%;
      transform: translateY(-50%);
      left: -20px;
      color: $navActive;
    }
    100% {
      top: 50%;
      transform: translateY(-50%);
      left: -25px;
    }
  }

  @include bgHorizontal();

  .blink {
    animation: 1s blink step-end infinite;
  }
  @include blink( $white );
</style>