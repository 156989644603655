<template>
    <section>
        <div class="content" :class="status" :data-night="night">
            <h1><slot name="h1"></slot></h1>
            <h2><slot name="h2"></slot></h2>
        </div>
    </section>
</template>

<script>
    export default {
        name: "EntryContent",

        data: () => ({
            night: false
        }),

        created () {
            this.night = this.$store.getters.night
        },

        computed: {
            getNight () {
                return this.$store.getters.night
            }
        },

        watch: {
            getNight: function(status) {
                this.night = status
            }
        },

        props: {
            status: String
        }
    }
</script>

<style lang="scss">
section {
    @include container();
    max-width: 80%;
    padding: 0;

    .content {
        width: 80%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 1;

        &.page-open {
            animation: entryOpen .3s ease-in-out;
            animation-fill-mode: forwards;
        }

        &.page-closed {
            animation: entryClose .3s ease-in-out;
            animation-fill-mode: forwards;
        }

        h1, h2 {
            color: #ffffff;
            font-weight: 400;
            text-transform: uppercase;
        }

        &[data-night="true"] {
            h1, h2 {
                color: $sky;
            }
        }

        h1 {
            font-size: 2.4rem;
            letter-spacing: -0.1rem;
            margin: 0;
            line-height: 3rem;
            text-align: center;

            @media screen and (min-width: 576px) {
                text-align: left;
            }

            @media screen and (min-width: 650px) {
                font-size: 4rem;
                line-height: 4.4rem;
            }

            @media screen and (min-width: 800px) {
                font-size: 5.5rem;
                letter-spacing: -0.1rem;
                line-height: 5rem;
            }
        }

        h2 {
            font-size: 1.6rem;
            line-height: 1.6rem;
            text-align: center;
            margin: 0;

            @media screen and (min-width: 576px) {
                text-align: left;
            }

            span.br {
                @media screen and (min-width: 576px) {
                    display: block;
                }
            }

            @media screen and (min-width: 650px) {
                font-size: 2rem;
                line-height: 2rem;
                letter-spacing: -0.08rem;
            }

            @media screen and (min-width: 800px) {
                font-size: 2.2rem;
                line-height: 2.2rem;
            }
        }
    }
}
@keyframes entryOpen {
    from {
        top: 130px;
        opacity: 0;
    }
    to {
        top: 70px;
        opacity: 1;

        @media screen and (min-width: 500px) {
            top: 100px;
        }
    }
}
@keyframes entryClose {
    from {
        top: 100px;
        opacity: 1;
    }
    to {
        top: 70px;
        opacity: 0;
    }
}
</style>