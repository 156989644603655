import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  { path: "/projects/websites", name: "Websites", component: () => import("../views/Websites.vue"), },
  { path: "/projects/designs",  name: "Designs",  component: () => import("../views/Designs.vue"), },
  { path: "/about-me",          name: "About",    component: () => import("../views/About.vue"), },
  // 404
  { path: '/:catchAll(.*)',     name: '404',      component: () => import('../views/404.vue') },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
