<template>
  <EntryContent :status="pageStatus">
    <template #h1>
      Hello, I'm Dan.
    </template>
    <template #h2>
      <span class="br">A British Full Stack Developer living </span>in Burnaby, Canada<span class="blink">&marker;</span>
    </template>
  </EntryContent>
</template>

<script>
import EntryContent from '@/components/EntryContent.vue'

export default {
  name: "Home",
  components: {
    EntryContent
  },

  data: () => ({
    pageStatus: ''
  }),

  created () {
    this.openPage()
    document.title = this.$store.getters.siteTitle
  },

  computed: {
    getPageStatus () {
      return this.$store.getters.pageStatus
    }
  },

  watch: {
    getPageStatus: function () {
      this.pageStatus = this.$store.getters.pageStatus
    }
  },

  methods: {
    openPage() {
      this.$store.commit('updatePage', 'page-open')
    }
  }
}
</script>
